/* eslint-disable jsx-a11y/alt-text */
// core
import React from "react";

// library
import { Link, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import { routes } from "../../App/routes";
import { changeMenuTab } from "../../../redux/reducer/main/action";

// assets
import styles from "./Header.module.scss";
import { ReactComponent as SearchSvg } from "../../../assets/images/icons/search.svg";
import { ReactComponent as BackSvg } from "../../../assets/images/icons/arrow-back.svg";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
// import { getRestrauntDetails } from "../../../redux/reducer/main/selectors";
import { getBrandDetailsSelector, getMenu } from "../../../redux/reducer/onboarding/selectors";
// import { ReactComponent as ArrowSvg } from "../../../assets/images/icons/arrow-down.svg";
// import { toTitleCase } from "../../../config/helper";
import history from "../../../config/history";

const urlParams = new URLSearchParams(window.location.search);
  let qrCode = urlParams.get("qr_code");
  const qrCodeFromStorage = localStorage.getItem('qrCode')
  if (urlParams.get("qr_code") !== null) {
    qrCode = urlParams.get("qr_code");
  }  else if (qrCodeFromStorage !== null && qrCodeFromStorage) {
    qrCode = qrCodeFromStorage;
  }
const links = [
  { name: "Special", url: `${routes.special}?qr_code=${qrCode}`, tabName: "special" },
  { name: "Food", url: `${routes.main}?qr_code=${qrCode}`, tabName: "food" },
  { name: "Beverages", url: `${routes.beverages}?qr_code=${qrCode}`, tabName: "beverages" },
  { name: "Desserts", url: `${routes.desserts}?qr_code=${qrCode}`, tabName: "desserts" },
  { name: "Drinks", url: `${routes.drinks}?qr_code=${qrCode}`, tabName: "drinks" },
  { name: "Store", url: `${routes.store}?qr_code=${qrCode}`, tabName: "store" },
];

export const Header = () => {
  const dispatch = useDispatch();
  const isDriveIn = useSelector(getBrandDetailsSelector)
  const handleChangeTab = (data) => {
		const item = document.getElementById(`${data}Super`);
		if(item){
			item.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "center",
			});
		}
		if (data !== "special") {
			dispatch(changeMenuTab(data));
		}
	};
  const pathName = window.location.pathname;
  useEffect(() => {
    // timer
    console.log('pathName :>> ', pathName && pathName.replace(/\\|\//g,''));
    handleChangeTab(pathName && pathName.replace(/\\|\//g,''));
    const intervalId = setInterval(() => {
      if (time === -1) {
        clearInterval(intervalId);
      } else {
        setTimer(time--);
      }
    }, 1000);
  }, []);
  let time = 1;
  // const superCat = document.getElementById("superCat");
  // const superCatWidth = superCat && superCat.scrollWidth;
  const [timer, setTimer] = useState(time);
  window.addEventListener("load", () => {
    // document.getElementById('superCat').scrollLeft += 100;
    if (document.getElementById("superCat")) {
      document.getElementById("superCat").scrollTo({
        top: 0,
        left: 100,
        behavior: "smooth",
      });
      const intervalId = setInterval(() => {
        if (time === 0) {
          clearInterval(intervalId);
        } else {
          handleBackToScroll(time--);
        }
      }, 1000);
    }
  });
  const handleBackToScroll = () => {
    if (document.getElementById("superCat")) {
      document.getElementById("superCat").scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };
  const menu = useSelector(getMenu);
  const rest = menu && menu.restraunt_details && menu.restraunt_details;


  const menuData = menu && menu.menu;
  const data = useSelector(getMenu);
  const menuKeys = menuData && Object.keys(menuData);
  let processedData = {};
  menuKeys && menuKeys.length && menuKeys.map((val) => {
    const inside_menu = Object.keys(data.menu[val]);
  inside_menu.map((inside) => {
    let category = data.menu[val][inside].filter(
      (val_data, i) => {
        return val_data.is_special === true;
      }
    );
    if (category.length > 0) {
      processedData = { ...processedData, [inside]: category };
    }
  });
});

const makeArray = processedData && Object.values(processedData);
  return (
    <header className={styles.header}>
      
      <div className={styles.top}>
       <h3>
          <span>
              {isDriveIn ? (
                <BackSvg
                  style={{ position: "relative", top: "7px" }}
                  onClick={() => history.push(`${routes.drivein}?qr_code=${qrCode}`)}
                />
              ) : (
                ""
              )}
          </span>{" "}
          <img src={rest && rest.restraunt_image} className="restRoundImage" />
          <div className="dropdown">
            <span>
              {rest && rest.restraunt_name}
            </span>
          </div>
        </h3>
        <ul
          style={{
            display: "-webkit-box !important",
            overflowX: "scroll !important",
          }}
        >
          <li>
            <Link to={`${routes.search}?qr_code=${qrCode}`} className={styles.icon}>
              <SearchSvg />
            </Link>
          </li>
        </ul>
      </div>
      <nav>
        <ul id="superCat">
          {links.map((link) => (
            menuData && menuData[link.tabName] || link.tabName === 'special' && makeArray && makeArray.length ? <li key={link.name}>
              <NavLink
                key={link.name}
                id={`${link.tabName}Super`}
                onClick={() => handleChangeTab(link.tabName)}
                to={link.url}
              >
                {link.name}
              </NavLink>
            </li>: ''
          ))}
        </ul>
      </nav>
      
    </header>
  );
};
