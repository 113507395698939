/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
// core
import React, {useEffect} from "react";

import styles from "../Special/Special.module.scss";
import { useSelector } from "react-redux";
import {
	getBrandDetailsSelector,
	getMenu,
    getRestaurantDetailsSelector,
} from "../../../../redux/reducer/onboarding/selectors";

import { useDispatch } from "react-redux";
import GridRestraunt from "../../../common/DriveIn/GridRestraunt";
import { loadMenuData } from "../../../../redux/reducer/main/action";

export const DriveIn = () => {
	const data = useSelector(getMenu);

	const dispatch = useDispatch();
	const brandData = useSelector(getBrandDetailsSelector);

    useEffect(() => {
        if(brandData === null || !brandData) {
          dispatch(loadMenuData());
        }
        
      }, []);
	const rest = useSelector(getRestaurantDetailsSelector);
    const banner = rest && rest.feature_image;
	const name = rest && rest.restraunt_name;
	return (
			<main
				className="special"
				style={{ height: "calc(100dvh - 222px)", overflow: "auto", padding: "16px" }}
			>
                {
                    rest ? 
                    <section>
                    <div className={styles.banner}>
                        <div className={styles.image}>
                        <img src={banner} />
                        <h3>Welcome to <br /> {name && name}</h3>
                        </div>
                    </div>
                    </section> : null
                }
				<section>
					<GridRestraunt
						toggle
					/>
				</section>
			</main>
	);
};
