export const routes = Object.freeze({
	register: "/register",
	verify: "/verify",
	getStarted: "/get-started",
	join: "/join",
	main: "/food",
	drinks: "/drinks",
	store: "/store",
	special: "/view_catalogue",
	desserts: "/desserts",
	beverages: "/beverages",
	search: "/search",
	placeOrder: "/place-order",
	checkout: "/checkout",
	coupons: "/coupons",
	orderRating: "/order-rating",
	event: "/event",
	eventCalendar: "/event-calendar",
	tickets: "/tickets",
	bookingConfirmation: "/booking-confirmation",
	drivein: "/drivein"
});
