import { takeEvery, call, put, select } from "redux-saga/effects";
import { types } from "../reducer/onboarding/types";
import { apiUrls } from "../../config/apiUrls";
import {
  callApi,
  callApiNoAuth,
  displayNotification,
} from "../../config/helper";
import { routes } from "../../components/App/routes";
import history from "../../config/history";
import {
  fetchOTPSuccess,
  getTableCode,
  getTableJoinDetails,
  joinCode,
  loader,
  loadTableJoinDetails,
  submitTableJoinCode,
} from "../reducer/onboarding/actions";
import { getRouting } from "../reducer/onboarding/selectors";
import { baseUrl } from "../../config/config";
import { upload } from "../../config/request";
import {
  loadOrderDetails,
  loadQrDetails,
  placeOrder,
} from "../reducer/main/action";
import { modalsActions } from "../modals/actions";
// const history = useHistory();
function* getOTP(action) {
  try {
    const selectRoute = yield select(getRouting);
    const phoneNumber = action.payload.phone;
    const apiUrl = `${apiUrls.phoneNumber}/${phoneNumber}`;
    yield put(loader(true));
    const response = yield callApiNoAuth(apiUrl);
    const type = JSON.parse(localStorage.getItem("type"));
    const whatsappIntegration = JSON.parse(
      localStorage.getItem("whatsAppIntegration")
    );
    const route = yield select(getRouting);
    const query = route && route.location && route.location.query;
    const country = localStorage.getItem("country");
    if (response && response.data && response.data.success) {
      // if user is verified then redirect to main page
      if (response && response.data && response.data.verified_user) {
        localStorage.setItem("token", JSON.stringify(response.data.auth_token));
        localStorage.setItem(
          "customerId",
          JSON.stringify(response.data.customer_id)
        );
        // localStorage.setItem(
        //   "qrCode",
        //   JSON.stringify(selectRoute.location.query.qr_code)
        // );
        if (whatsappIntegration === true) {
          console.log(`chekout`);
          history.push(`${routes.checkout}?order_id=${query.order_id}`);
        } else if (type === "dine_in") {
          history.push(routes.special);
          yield put(loadTableJoinDetails(selectRoute.location.query.qr_code));
        } else {
          history.push(routes.special);
        }
        yield put(loader(false));
      } else {
        yield put(loader(false));
        yield put(fetchOTPSuccess(response));
        if (whatsappIntegration === true) {
          const qrCode = JSON.parse(localStorage.getItem("qrCode"));
          if (
            country &&
            country.length &&
            country.toLowerCase() == JSON.stringify("aus")
          ) {
            history.push(`${routes.getStarted}/?mobile=${phoneNumber}`);
          } else {
            history.push(
              `${routes.verify}?mobile=${phoneNumber}&qr_code=${qrCode}`
            );
          }
        } else {
          if (country && country.length && country.toLowerCase()) {
            history.push(`${routes.getStarted}/?mobile=${phoneNumber}`);
          } else {
            console.log(`this is hrere`);
            history.push(
              `${routes.verify}?mobile=${phoneNumber}&qr_code=${selectRoute.location.query.qr_code}`
            );
          }

          yield put(loader(false));
        }
      }
    }
  } catch (error) {
    yield put(loader(false));
    console.log(error);
  }
}

function* verifyOTP(action) {
  try {
    const selectRoute = yield select(getRouting);
    const type = JSON.parse(localStorage.getItem("type"));
    // const phone = selectRoute.location.query.mobile;
    yield put(loader(true));
    const payloadData = action.payload;
    const urlParams = new URLSearchParams(window.location.search);
    const phone = urlParams.get("mobile");
    const apiUrl = `${apiUrls.phoneNumber}/${phone}`;
    const response = yield callApiNoAuth(apiUrl, payloadData, "POST");
    const whatsappIntegration = JSON.parse(
      localStorage.getItem("whatsAppIntegration")
    );
    const route = yield select(getRouting);
    const query = route && route.location && route.location.query;
    if (response && response.data && response.data.status === true) {
      yield put(fetchOTPSuccess(response));
      // store local storage data
      localStorage.setItem("token", JSON.stringify(response.data.auth_token));
      localStorage.setItem(
        "customerId",
        JSON.stringify(response.data.customer_id)
      );
      localStorage.setItem(
        "newCustomer",
        JSON.stringify(response.data.new_customer)
      );
      if (response.data.new_customer) {
        yield put(loader(false));
        history.push(routes.getStarted);
      } else if (type === "dine_in") {
        yield put(loadTableJoinDetails(selectRoute.location.query.qr_code));
        yield put(loader(false));
      } else if (
        !response.data.new_customer &&
        type === "dine_in" &&
        whatsappIntegration === true
      ) {
        yield put(loadQrDetails());
        history.push(`${routes.checkout}?order_id=${query.order_id}`);
      } else {
        yield put(loader(false));
        history.push(routes.special);
      }
    } else {
      displayNotification("Please enter correct otp");
      yield put(loader(false));
    }
  } catch (error) {
    yield put(loader(false));
    console.log(error);
  }
}

function* getTableDetail(action) {
  yield put(loader(true));
  try {
    const selectRoute = yield select(getRouting);
    const payloadData = { qr_code: action.payload };
    const apiUrl = apiUrls.getTableDetails;
    const response = yield callApi(apiUrl, payloadData, "POST");
    const whatsAppIntegration = localStorage.getItem("whatsAppIntegration");
    if (response && response.data && response.data.status === "occupied") {
      yield put(loader(false));
      history.push(
        `${routes.join}?qr_code=${selectRoute.location.query.qr_code}`
      );
    } else if (
      response &&
      response.data &&
      response.data.customer_place_order
    ) {
      history.push(routes.special);
      yield put(loader(false));
      localStorage.setItem(
        "customerPlaceOrder",
        response.data.customer_place_order
      );
      localStorage.setItem("onlinePayment", response.data.online_payment);
      // localStorage.setItem("joinCode", response.data.table_joining_code);
      localStorage.setItem("orderId", JSON.stringify(response.data.order_id));
      if (whatsAppIntegration) {
        yield put(submitTableJoinCode(response.data.table_joining_code));
      }
      yield put(getTableJoinDetails(response.data.menu));
    } else {
      yield put(loader(false));
      localStorage.clear();
      history.push(routes.register);
      displayNotification(response.message);
    }
  } catch (error) {
    yield put(loader(false));
    console.log(error);
  }
}

function* joinWithCode(action) {
  try {
    const qrCode = JSON.parse(localStorage.getItem("qrCode"));
    const whatsAppIntegration = JSON.parse(
      localStorage.getItem("whatsAppIntegration")
    );
    // const joinCode = localStorage.getItem("joinCode");
    let payloadData = {
      qr_code: qrCode,
      table_joining_code: action.payload,
    };
    // if (whatsAppIntegration) {
    //   payloadData = { ...payloadData, table_joining_code: joinCode };
    // }
    const location = window.location.pathname;
    // const type = JSON.parse(localStorage.getItem("type"));
    const apiUrl = apiUrls.joinWithCode;
    const response = yield callApi(apiUrl, payloadData, "POST");
    const route = yield select(getRouting);
    const query = route && route.location && route.location.query;
    if (response && response.data && response.data.status === "failure") {
      yield put(loader(false));
      if (location === routes.register) {
        // localStorage.clear();
        // history.push(routes.register);
        history.push(
          query.type === undefined
            ? routes.register
            : query.cn //If country then url with cn name query otherwise without  it
            ? `${routes.register}?type=${query.type}&cn=${query.cn}&qr_code=${query.qr_code}`
            : `${routes.register}?type=${query.type}&qr_code=${query.qr_code}`
        );
      } else {
        displayNotification(response.data.reason);
        history.push(routes.join);
      }
    } else if (
      response &&
      response.data &&
      response.data.customer_place_order === true
    ) {
      yield put(getTableJoinDetails(response.data.menu));
      yield put(loader(false));
      // history.push(routes.special);
      if (response.data.menu && response.data.menu.restraunt_details) {
        localStorage.setItem(
          "restrauntId",
          JSON.stringify(response.data.menu.restraunt_details.restraunt_id)
        );
      }
      localStorage.setItem(
        "customerPlaceOrder",
        response.data.customer_place_order
      );
      localStorage.setItem("onlinePayment", response.data.online_payment);
      localStorage.setItem("joinCode", action.payload);
      localStorage.setItem("orderId", JSON.stringify(response.data.order_id));
      if (location === "/join" || location === "/login") {
        if (whatsAppIntegration === true) {
          history.push(routes.checkout);
        } else {
          history.push(routes.special);
        }
      }
      yield put(loadOrderDetails());
    } else {
      yield put(loader(false));
      if (location === "/login") {
        // displayNotification("Plea");
        return;
      } else {
        displayNotification(response.message);
      }
    }
  } catch (error) {
    yield put(loader(false));
    console.log(error);
  }
}

function* getTableCodeSaga(action) {
  // yield put(loader(true));
  const payloadData = action.payload;
  // const whatsAppIntegration = JSON.parse(
  //   localStorage.getItem("whatsAppIntegration")
  // );
  try {
    const qrCode = JSON.parse(localStorage.getItem("qrCode"));
    const response = yield callApi(
      apiUrls.getTableCode,
      { qr_code: qrCode },
      "POST"
    );
    if (response && response.data && response.data.table_joining_code) {
      yield put(getTableCode(response.data));
      yield put(loader(false));
      localStorage.setItem(
        "customerPlaceOrder",
        response.data.customer_place_order
      );
      localStorage.setItem("onlinePayment", response.data.online_payment);
      // whatsAppIntegration &&
      //   localStorage.setItem("joinCode", response.data.table_joining_code);
      localStorage.setItem("orderId", JSON.stringify(response.data.order_id));
      if (payloadData === "placeOrderApi") {
        yield put(placeOrder());
      } else if (payloadData === "showJoinCode") {
        localStorage.setItem("joinCode", response.data.table_joining_code);
        yield put(modalsActions.setShowModal("Share"));
        yield put(loader(false));
      }
    } else if (response.message === "Incorrect padding") {
      localStorage.clear();
      history.push(routes.register);
    }
  } catch (error) {
    yield put(loader(false));
    console.log(`error`, error);
  }
}
function* changeNameSaga(action) {
  try {
    yield put(loader(true));
    const qrCode = JSON.parse(localStorage.getItem("qrCode"));
    const type = JSON.parse(localStorage.getItem("type"));
    const data = { customer_name: action.payload.customer_name };
    let formData = new FormData();
    for (const key in data) {
      formData.append("customer_name", JSON.stringify(data[key]));
    }
    const country = localStorage.getItem("country");
    const urlParams = new URLSearchParams(window.location.search);
    const phone = urlParams.get("mobile");
    const whatsappIntegration = JSON.parse(
      localStorage.getItem("whatsAppIntegration")
    );
    const route = yield select(getRouting);
    const query = route && route.location && route.location.query;
    const apiUrl =
      baseUrl +
      `/customer_profile/${JSON.parse(localStorage.getItem("customerId"))}`;
    let response;
    if (country == "aus") {
      const apiName = `${apiUrls.phoneNumber}/${phone}`;
      formData = {
        otp_value: "000000",
        customer_name: action.payload.customer_name,
      };
      response = yield callApi(apiName, formData, "POST");
    } else {
      response = yield call(upload, apiUrl, {
        method: "POST",
        body: formData,
      });
    }
    if (response && response.data && response.data.status === "success") {
      if (type === "dine_in") {
        yield put(loadTableJoinDetails(qrCode));
        yield put(loader(false));
        yield put(loadQrDetails());
        if (whatsappIntegration === true) {
          // console.log(`chekout`);
          history.push(`${routes.checkout}?order_id=${query.order_id}`);
        }
      } else {
        yield put(loader(false));
        // yield put(loadMenuData());
        history.push(routes.special);
      }
    } else if (response && response.data && response.data.status) {
      if (country == "aus") {
        localStorage.setItem(
          "customerId",
          JSON.stringify(response.data.customer_id)
        );
        localStorage.setItem("token", JSON.stringify(response.data.auth_token));
        history.push(routes.special);
      }
    } else {
      // history.push()
      yield put(loader(false));
      displayNotification(response.message || "failure");
    }
  } catch (error) {
    yield put(loader(false));
    console.log(`error`, error);
  }
}

export function* onboardingSaga() {
  yield takeEvery(types.SEND_OTP, getOTP);
  yield takeEvery(types.VERIFY_OTP, verifyOTP);
  yield takeEvery(types.LOAD_TABLE_JOIN_DETAILS, getTableDetail);
  yield takeEvery(types.SUBMIT_TABLE_JOIN_CODE, joinWithCode);
  yield takeEvery(types.LOAD_GET_TABLE_CODE, getTableCodeSaga);
  yield takeEvery(types.CHANGE_NAME, changeNameSaga);
}
