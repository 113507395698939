/* eslint-disable no-mixed-operators */
import React from "react";

//library
// import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// components
// import { AddButton } from "../AddButton/AddButton";
import { VeganStatus } from "../VeganStatus/VeganStatus";
import { modalsActions } from "../../../redux/modals/actions";

// assets
import styles from "./ListItems.module.scss";
import { ReactComponent as ArrowSvg } from "../../../assets/images/icons/arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getContainsEgg,
  // getPlaceOrderSelector,
  getSelectVegOnly,
} from "../../../redux/reducer/main/selectors";
import { Instruction } from "../Instruction/Instruction";
import LazyPlaceholder from "../LazyPlaceholder";
import LazyLoad from "react-lazyload";
import { currencySymbol } from "../../../config/helper";
import { getAddModalDataSelector } from "../../../redux/reducer/onboarding/selectors";
import { getAddModalData } from "../../../redux/reducer/onboarding/actions";

export const ListItems = ({
  items,
  mod,
  toggle,
  list,
  instruction,
  setShow,
  categoryName,
  title,
  instructionAdded,
  setInstructionAdded,
}) => {
  // const dispatch = useDispatch();

  // const [instructionAdded, setInstructionAdded] = useState(false);
  const showItems = (e) => {
    e.target.parentElement.classList.toggle(styles.hide);
  };
  const dispatch = useDispatch();
  const addProduct = (data) => {
    dispatch(getAddModalData(data));
    dispatch(modalsActions.setShowModal("Add Product"));
  };
  // const getPlaceOrderData = useSelector(getPlaceOrderSelector);
  const vegOnlyState = useSelector(getSelectVegOnly);
  const containsEggState = useSelector(getContainsEgg);
  // const handleAddItem = (data) => {
  //   // add directly
    
  // };
  const onClickScroll = (id) => {
    document.body.style.overflow = "";
    if (document.getElementById(id)) {
      const element = document.getElementById(id);
      const headOffset = 114;
      const elementPosition = element.getBoundingClientRect().top;
      window.scrollTo({
        top: elementPosition - headOffset,
        behavior: "smooth",
      });
    }
  };

  // const truncate = (string, noWords) => string.length > noWords ? `${string.substring(0, noWords)}...` : string;
  return (
    <React.Fragment>
      <React.Fragment>
      <div
              // key={`parentDiv${index}`}
              className={`${styles.items} ${
                title === "Previous order" ? styles.hide : ""
              } ${mod ? styles.mod : ""}`}
              >
        {!list ? (
          items && items.length ? (
            items.map((item, index) => (
              
              <React.Fragment>
                {toggle ? (
                  <h4
                  style={{marginTop: '19px', marginBottom: '20px'}}
                    className={`${styles.title} ${styles.toggle}`}

                    onClick={(e) => {
                      showItems(e);
                      onClickScroll(
                        categoryName &&
                          categoryName.length &&
                          categoryName[index]
                      );
                    }}
                    id={
                      categoryName && categoryName.length && categoryName[index]
                    }
                  >
                    {categoryName && categoryName.length && categoryName[index]}
                    <hr />
                    <ArrowSvg />
                  </h4>
                ) : (
                  <h4
                    className={styles.title}
                    id={
                      categoryName && categoryName.length && categoryName[index]
                    }
                  >
                    {categoryName && categoryName.length && categoryName[index]}
                  </h4>
                )}
              <LazyLoad key={index} height={200}  placeholder={<LazyPlaceholder />}>
                <div className={styles.panel}>
                  {item &&
                    item.length &&
                    item.map((product, index) =>
                      vegOnlyState === true ? (
                        containsEggState === true ? (
                          // product.contains_egg === true ||
                          // product.item_type === "veg" 
                          product.item_type !== 'non-veg' && (
                            <Link
                              key={index}
                              to={'#'}
                              className={styles.item}
                              onClick={()=> addProduct(product)}
                            >
                              {product.item_image_url && (
                                <img
                                  src={product.item_image_url}
                                  onClick={() => addProduct(product)}
                                  alt={product.item_name}
                                />
                              )}
                              <div className={styles.description} onClick={()=> addProduct(product)}>
                                <h3>{product.item_name}</h3>
                                {
																	product && product.item_description && product.item_description.length
																	&&
																	<p className={`${styles.descriptionClamp} ${product.item_image_url? styles.reducedWidth: ''}`}>
																		{product.item_description}
																	</p>
																}
                                <div className={styles.info}>
                                  <div
                                    className={styles.price}
                                    // onClick={() => addProduct(product)}
                                  >
                                    <VeganStatus
                                      type={product.item_type}
                                      egg={product.contains_egg}
                                    />
                                    <h4>
                                    {currencySymbol()}
                                      {product.item_price_details &&
                                        product.item_price_details.length &&
                                        product.item_price_details[0]
                                          .item_price}
                                    </h4>
                                  </div>
                                  
                                </div>
                              </div>
                            </Link>
                          )
                        ) : (
                          product.item_type === "veg" &&
                          product.contains_egg === false && (
                            <Link
                              key={index}
                              to={'#'}
                              className={styles.item}
                              onClick={()=> addProduct(product)}
                            >
                              {product.item_image_url && (
                                <img
                                  src={product.item_image_url}
                                  alt={product.item_name}
                                  onClick={()=> addProduct(product)}
                                />
                              )}
                              <div className={styles.description} onClick={()=> addProduct(product)}>
                                <h3>{product.item_name}</h3>
                                {
																	product && product.item_description && product.item_description.length
																	&&
																	<p className={`${styles.descriptionClamp} ${product.item_image_url? styles.reducedWidth: ''}`}>
																		{product.item_description}
																	</p>
																}
                                <div className={styles.info}>
                                  <div
                                    className={styles.price}
                                    // onClick={() => addProduct(product)}
                                  >
                                    <VeganStatus
                                      type={product.item_type}
                                      egg={product.contains_egg}
                                    />
                                    <h4>
                                    {currencySymbol()}
                                      {product.item_price_details &&
                                        product.item_price_details.length &&
                                        product.item_price_details[0]
                                          .item_price}
                                    </h4>
                                  </div>
                                 
                                </div>
                              </div>
                            </Link>
                          )
                        )
                      ) : (
                        // Place order section
                        <Link
                          key={index}
                          to={'#'}
                          className={styles.item}
                          onClick={()=> addProduct(product)}
                        >
                          {product.item_image_url && (
                            <img
                              src={product.item_image_url}
                              // onClick={() => addProduct(product)}
                              alt={product.item_name}
                            />
                          )}
                          <div className={styles.description}>
                            <h3>
                              {product.item_name}
                            </h3>
                            {
                              product && product.item_description && product.item_description.length
                              &&
                              <p className={`${styles.descriptionClamp} ${product.item_image_url? styles.reducedWidth: ''}`}>
                                {product.item_description}
                              </p>
                            }
                            <div className={styles.info}>
                              <div
                                className={styles.price}
                              >
                                <VeganStatus
                                  type={product.item_type}
                                  egg={product.contains_egg}
                                />
                                <h4>
                                {currencySymbol()}
                                  {product.item_price_details &&
                                    product.item_price_details.length &&
                                    product.item_price_details[0].item_price}
                                </h4>
                              </div>
                             
                            </div>
                          </div>
                        </Link>
                      )
                    )}
                </div>{" "}
                </LazyLoad>
              </React.Fragment>
              // </div>
            ))
          ) : (
            ""
          )
        ) : (
          <React.Fragment>
        
            {toggle ? (
              <h4
                className={`${styles.title} ${styles.toggle}`}
                onClick={(e) => showItems(e)}
              >
                {title}
                <hr />
                <ArrowSvg />
              </h4>
            ) : (
              toggle ? (
                <h4
                // style={{marginTop: '19px'}}
                  className={`${styles.title} ${styles.toggle}`}

                  onClick={(e) => {
                    showItems(e);
                  }}
                  id={title}
                >
                  {title}
                  <hr />
                  <ArrowSvg />
                </h4>
              ) : (
                <h4
                  className={styles.title}
                  id={
                    title
                  }
                >
                  {title}
                </h4>
              ))}
            
            <div className={`${styles.panel} ${styles.mod}`}>
              {items && items.length
                ? items.map((item, index) => (
                  // <LazyLoad key={`parentDiv${index}`} height={200}  placeholder={<LazyPlaceholder />}>
                    <div
                      key={index}
                      id={item.item_name}
                      className={styles.item}
                      onClick={()=> addProduct(item)}
                    >
                      <div className={styles.description}>
                        <div className={styles.list}>
                          <VeganStatus
                            type={item.item_type}
                            egg={item.contains_egg}
                          />
                          <div>
                            <h4>{item.item_name}</h4>
                            <div className={styles.info}>
                              <div className={styles.price}>
                                <h5>{currencySymbol()}{item.item_price}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                    // </LazyLoad>
                  ))
                : ""}
              <div>
                {instructionAdded && title !== "Previous order" ? (
                  <Instruction
                    setInstructionAdded={setInstructionAdded}
                    title="Cooking instruction:"
                    description=""
                    setShow={setShow}
                    black
                  />
                ) : (
                  instruction && (
                    <div className={styles.link} onClick={() => setShow(true)}>
                      Add cooking instruction
                    </div>
                  )
                )}
              </div>
            </div>
            {/* </div> */}
          </React.Fragment>
        )}
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};
