import React from "react";
import platePng from "../../../assets/images/plate.png";

const GridBox = ({ product, styles, handleOnClick, index }) => {
	return (
		<div
			id={product.title}
			className={styles.item}
			key={`${index}grid`}
		>
			<div
				className={styles.image}
				onClick={() => handleOnClick(product.brand_id)}
			>
				{product.item_image_url && product.item_image_url !== "" ? (
					<img src={product.item_image_url} alt={product.item_name} />
				) : (
					<img src={platePng} alt={product.item_name} />
				)}
			</div>
			<div
				onClick={() => handleOnClick(product.brand_id)}
				className={styles.description}
			>
				<h4 className={styles.itemTitle}>{product.brand_name}</h4>
				<div className={styles.info}>
				</div>
			</div>
		</div>
	);
};

export default GridBox;
