export const apiUrls = {
  phoneNumber: "/login/phone/v2",
  getTableDetails: "/table/{customerId}/join",
  joinWithCode: "/table/{customerId}/join_with_code",
  getTableCode: "/table/{customerId}/get_table_code",
  getOrderDetails: "/orders/{orderId}/customer/{customerId}",
  placeOrderDineIn: "/orders/{orderId}/customer/{customerId}",
  getCouponsData: "/promo/coupons/{customerId}",
  applyCouponApi: "/orders/{orderId}/coupons/{customerId}/apply",
  tipApi: "/orders/{orderId}/customer/{customerId}/tips",
  checkoutPayment: "/orders/{orderId}/customer/{customerId}/checkout",
  initiatePayment: "/orders/{orderId}/customer/{customerId}/initiate/payment",
  acknowledgePayment: "/orders/{orderId}/customer/{customerId}/acknowledge",
  submitRatingData: "/reviews/{customerId}/orders/{orderId}",
  restrauntDetails: "/restraunt/{restrauntId}/details/{customerId}",
  complaints: "/complaints/{customerId}/add",
  pickUpMenuData: "/orders/customer/{customerId}/menu/pick_up",
  deliveryMenuData: "/orders/customer/{customerId}/menu/delivery",
  placePickup: "/orders/{orderId}/customer/{customerId}/pick_up/add",
  placeDelivery: "/orders/{orderId}/customer/{customerId}/delivery/add",
  customizeOrder: "/orders/{orderId}/customer/{customerId}/customize_order",
  placePickupDelivery: "/orders/{orderId}/customer/{customerId}/",
  addAddress: "/orders/{orderId}/customer/{customerId}/delivery/verify_address",
  changeName: "/customer_profile/{customerId}",
  tableDetails: "/orders/{orderId}/table-details",
};
