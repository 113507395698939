// core
import React, { useState } from 'react';


// assets
import styles from './Footer.module.scss';
import { ReactComponent as CloseSvg } from "../../../assets/images/icons/close.svg";
import { currentMenuTab, getContainsEgg, getSelectVegOnly } from '../../../redux/reducer/main/selectors';
import { useSelector } from 'react-redux';
import { getMenu } from '../../../redux/reducer/onboarding/selectors';
import { containsEgg, vegOnly } from '../../../redux/reducer/main/action';
import { useDispatch } from 'react-redux';
import { routes } from '../../App/routes';

export const Footer = ({noMenu}) => {
  const [showMenu, setShowMenu] = useState(false);
  // const placeOrders = useSelector(getPlaceOrderSelector);

  const menu = useSelector(getMenu)
  const currentTab = useSelector(currentMenuTab)
  const menuData = menu && menu.menu;
  const foodMenu = menuData && menuData[currentTab] &&  Object.values(menuData[currentTab]);
  const foodMenuKey = menuData && menuData[currentTab] && Object.keys(menuData[currentTab]);
  const totalItems = foodMenu && foodMenu.length && foodMenu.flat().length;
  const containsEggState = useSelector(getContainsEgg);
  const onClickScroll = id => {
    setShowMenu(false);
    document.body.style.overflow = '';
    if(document.getElementById(id)) {
      // document.getElementById(id).scrollIntoView({
      //   behavior: 'smooth'
      // });
      const element = document.getElementById(id);
      const headOffset = 114;
      const elementPosition = element.getBoundingClientRect().top;
      window.scrollTo({
        top:  elementPosition - headOffset,
        behavior: "smooth"
      });
    }
   
  }
  const dispatch = useDispatch();
  const vegOnlyState = useSelector(getSelectVegOnly);
  const handleOpenMenu = () => {
    setShowMenu(true)
    document.body.style.overflow = 'hidden';
  }
  const handleCloseMenu = () => {
    document.body.style.overflow = '';
    setShowMenu(false)
  }
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  return (
    <>
      <div className={`${styles.menu} ${showMenu ? styles.open : ''}`} style={{zIndex: 999}}>
        <div className={styles.inner}>
          <h3>
            Menu
            <span className={styles.close} onClick={() => handleCloseMenu()}>
            <CloseSvg />
          </span>
          </h3>
          <hr />
          <ul className={styles.availability}>
            <li>
              Veg Only
              <label className={styles.radio}>
                <input type='checkbox' hidden defaultChecked={vegOnlyState} onChange={(e)=> dispatch(vegOnly(e.target.checked))} />
                <span />
              </label>
            </li>
            <li>
              Contains Egg
              <label className={styles.radio}>
                <input type='checkbox' id="checkContainsEgg" defaultChecked={containsEggState} hidden disabled={!vegOnlyState} onChange={(e)=> dispatch(containsEgg(e.target.checked))} />
                <span />
              </label>
            </li>
          </ul>
          <hr />
          {currentPath !== routes.special ? <ul className={styles.availability} style={{height: 200, overflowY: 'scroll'}}>
            <li>All <span>{totalItems}</span></li>
            {foodMenuKey && foodMenuKey.length && foodMenuKey.map((food, index) => <li key={`foodMenuKey${index}`} onClick={()=> onClickScroll(food)}>{food}<span>{foodMenu[index].length}</span></li>)}
          </ul> : ''}
        </div>
      </div>
      <footer className={styles.footer}>
        {!noMenu && <div className={`${styles.menuBtn} btn`} style={{bottom: '2.5rem'}} onClick={() => handleOpenMenu()}>
          Menu
        </div>}
        <ul className={styles.nav}>
          {/* <li>
            <NavLink to={routes.event}>
              <LightningSvg />
            </NavLink>
          </li>
          <li>
            <NavLink to={routes.special} className={classActive}>
              <BookSvg />
            </NavLink>
          </li>
          <li className={styles.notEmpty}>
          {placeOrders.length > 0 ? <span className={styles.count}>{placeOrders.length || 0}</span> : ''}
            <NavLink to={orderDetails && orderDetails.length > 1 && placeOrders.length === 0 ? routes.checkout : routes.placeOrder}>
              <ShoppingSvg />
            </NavLink>
          </li> */}
        </ul>
      </footer>
    </>
  );
};

