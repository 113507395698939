import { types } from "./types";

export function fetchOTP(data) {
	return {
		type: types.SEND_OTP,
		payload: data,
	};
}

export const fetchOTPSuccess = (user) => {
	return {
		type: types.SEND_OTP_SUCESS,
		payload: user,
	};
};
export const verifyOTP = (data) => {
	return {
		type: types.VERIFY_OTP,
		payload: data,
	};
};
export const getTableJoinDetails = (data) => {
	return {
		type: types.GET_TABLE_JOIN_DETAILS,
		payload: data,
	};
};
export const loadTableJoinDetails = (data) => {
	return {
		type: types.LOAD_TABLE_JOIN_DETAILS,
		payload: data,
	};
};
export const submitTableJoinCode = (data, status) => {
	return {
		type: types.SUBMIT_TABLE_JOIN_CODE,
		payload: data,
		status,
	};
};
export const getTableCode = (data) => {
	return {
		type: types.GET_TABLE_CODE,
		payload: data,
	};
};
export const loadGetTableCode = (data) => {
	return {
		type: types.LOAD_GET_TABLE_CODE,
		payload: data,
	};
};
export const getAddModalData = (data) => {
	return {
		type: types.GET_ADD_MODAL_DATA,
		payload: data,
	};
};
export const changeName = (data) => {
	return {
		type: types.CHANGE_NAME,
		payload: data,
	};
};
export const loader = (data) => {
	return {
		type: types.LOADER,
		payload: data,
	};
};

export function getDriveInBrands(data) {
	return {
		type: types.GET_DRIVE_IN_BRANDS,
		payload: data,
	}
}

export function getRestaurantDetails(data) {
	return {
		type: types.GET_RESTAURANT_DETAILS,
		payload: data,
	}
}