import React from "react";
import styles from "../GridItems/GridItems.module.scss";
import { ReactComponent as ArrowSvg } from "../../../assets/images/icons/arrow.svg";
import GridBox from "./GridBox";
import { getBrandDetailsSelector } from "../../../redux/reducer/onboarding/selectors";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrandMenu } from "../../../redux/reducer/main/action";
const GridRestraunt = ({ toggle, headerLine }) => {
	const brandData = useSelector(getBrandDetailsSelector);
	const dispatch = useDispatch();
	const handleOnClick = (id) => {
		dispatch(fetchBrandMenu(id));
	};
	return (
		<div className={`${styles.items}`}>
			<div className={styles.panel}>
				{(brandData || []).map((e) => (
					<GridBox
						headerLine={`Drive in's`}
						toggle
						handleOnClick={handleOnClick}
						product={e}
						styles={styles}
					/>
				))}
			</div>
		</div>
	);
};

export default GridRestraunt;
