import React from "react";

// assets
import styles from "./VeganStatus.module.scss";

export const VeganStatus = ({type, egg}) => {
  return (
    type === 'non-veg'
                        ? <span className={styles.nonVeg} />
                        : egg === 'true' ||
                        egg === true
                        ? <span className={styles.egg} />
                        : <span className={styles.vegan} />
    
  )
}