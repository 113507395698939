/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
// core
import React from 'react';

// components
import { GridItems, Layout } from "../../../common";

// assets
import styles from './Special.module.scss';
// import chocolate from '../../../../assets/images/chocolate.png';
// import sunset from '../../../../assets/images/sunset.png';
// import banner from '../../../../assets/images/special-banner.png';
import { useSelector } from 'react-redux';
import { getMenu } from '../../../../redux/reducer/onboarding/selectors';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeMenuTab, loadMenuData } from '../../../../redux/reducer/main/action';
import { useHistory } from 'react-router';
var jwt = require('jsonwebtoken');


export const Special = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const qrCode = urlParams.get("qr_code");
  
  if(qrCode !== null || qrCode !== 'null') {
    localStorage.setItem('qrCode', qrCode)
  } 
  const dispatch = useDispatch();
  // const menu = useSelector(getMenu)
  const generateToken = () => {
    //1. Dont use password and other sensitive fields
    //2. Use fields that are useful in other parts of the     
    //app/collections/models
    
    const user = {
      name: "toast",
      username: "toast",
      _id: 1
    }
    var u = {
      name: user.name,
      username: user.username,
      _id: user._id.toString(),
    };
    let token;
    return token = jwt.sign(u, 'teststagejwttoken', {
      expiresIn: 60 * 60 * 24 // expires in 24 hours
    }),
    localStorage.setItem('token', JSON.stringify(token));
    // console.log(token)
  }
  const data = useSelector(getMenu);
  useEffect(() => {
    generateToken();
    if(data === null || !data) {

      dispatch(loadMenuData());
    }
    
  }, [dispatch]);
  const restDetails = data && data.restraunt_details;
  const banner = restDetails && restDetails.feature_image;
  const menuData = data && data.menu;
  const menuKeys = menuData && Object.keys(menuData);
  let processedData = {};
  menuKeys && menuKeys.length && menuKeys.map((val) => {
    const inside_menu = Object.keys(data.menu[val]);
  inside_menu.map((inside) => {
    let category = data.menu[val][inside].filter(
      (val_data, i) => {
        return val_data.is_special === true;
      }
    );
    if (category.length > 0) {
      processedData = { ...processedData, [inside]: category };
    }
  });
});
const history = useHistory()
const categoryName = processedData && Object.keys(processedData);
const makeArray = processedData && Object.values(processedData);


if(makeArray && !makeArray.length) {
  menuKeys && menuKeys.length && menuKeys.reverse().map(el => {
    if(menuData && menuData[el] && Object.values(menuData[el]).length)
    {
      history.push(`/food?qr_code=${qrCode}`);
      document.getElementById(`${el}Super`) && document.getElementById(`${el}Super`).classList.add('active')
     return dispatch(changeMenuTab(el));
    }
  } )
}
  return (
    <Layout>
      <main className='special'>
        <section>
          <div className={styles.banner}>
            <div className={styles.image}>
              <img src={banner} />
              <h3>Welcome to <br /> {restDetails && restDetails.restraunt_name}</h3>
            </div>
          </div>
        </section>
        <section>
          <GridItems items={processedData && Object.values(processedData)} categoryName={categoryName} toggle />
        </section>
      </main>
    </Layout>
  );
};

