import React from "react";

// library
import { Link } from "react-router-dom";

// components
import { VeganStatus } from "../../VeganStatus/VeganStatus";
import { PrimaryButton } from "../../PrimaryButton/PrimaryButton";
import { WrapperModal } from "../WrapperModal/WrapperModal";

// assets
import styles from "./AddProductModal.module.scss";
// import styles from "./AddProductModal.module.scss";
import plate from "../../../../assets/images/icons/plate.svg";
import { ReactComponent as BackSvg } from "../../../../assets/images/icons/arrow-back.svg";
import { currencySymbol } from "../../../../config/helper";
import { getAddModalDataSelector } from "../../../../redux/reducer/onboarding/selectors";
import { useDispatch, useSelector } from "react-redux";
import { modalsActions } from "../../../../redux/modals/actions";

export const AddProductModal = () => {

  const onSubmit = (e) => {
    e.preventDefault();
  };
  const currentItemData = useSelector(getAddModalDataSelector);
  const tags = currentItemData && currentItemData.tags;
const itemPrizeDetails = currentItemData && currentItemData.item_price_details 
const itemSizeFirst = itemPrizeDetails && itemPrizeDetails.length && itemPrizeDetails[0] && itemPrizeDetails[0].item_size;
const addOnDetails = currentItemData && currentItemData.addon_details;
const dispatch = useDispatch();
  return (
    <div className={styles.modal}>
      <div className={styles.back} onClick={() => dispatch(modalsActions.setShowModal(''))} style={{boxShadow: 'none'}}>
        <BackSvg />
      </div>
      <div className={styles.top}>
        {currentItemData.item_image_url ? <img src={currentItemData ? currentItemData.item_image_url : plate} style={{width: '100%', opacity: 1}}/> : <img src={plate}/>}
      </div>
      <form className={`add-item-form ${styles.bottom}`} >
        <div className={styles.form}>
          <h3 className={styles.itemTitle}>
          <VeganStatus type={currentItemData.item_type} egg = {currentItemData.contains_egg} />
            {currentItemData && currentItemData.item_name && currentItemData.item_name}
          </h3>
          {tags && Array.isArray(tags) && tags.length ? tags.map((tag, indexTag) => <span key={indexTag} className={styles.chip}>
          {tag}
        </span>) : ''}
          <p>
           {currentItemData && currentItemData.item_description}.
          </p>
          <div className={styles.option}>
            <h4>Select Size</h4>
            {itemPrizeDetails && itemPrizeDetails.length && itemPrizeDetails.map((size, sizeIndex)=> <label className='radio'>
              <input type='radio' disabled hidden value={size.item_size} name="size" />
              <span className='icon' />
              {size.item_size}
              <span className={styles.price}>{currencySymbol()}{size.item_price}</span>
            </label>)}
          </div>
          <div className={styles.option}>
          {/* {addOnDetails && addOnDetails.length ? <h4>Add toppings <span>(optional)</span></h4> : ''} */}
            {addOnDetails && addOnDetails.length ? addOnDetails.map((addOn, addOnIndex) => 
            <React.Fragment key={`addonCategory${addOnIndex}`}>
              <h4>{addOn.addon_category_name}{addOn.addon_limit_optional === false ? '*' : ''} <span>(limit {addOn.addon_limit})</span></h4>
              {addOn && addOn.addon_items && addOn.addon_items.map(el => <label className='checkbox'>
              <input disabled type='checkbox' id={el.addon_id} hidden name={el.addon_id} />
              <span className='icon' />
              <span style={{width: '76%', wordBreak: 'break-word'}}>{el.addon_name}</span>
              <span className={styles.price}>{currencySymbol()}{el.addon_price && el.addon_price[0] && el.addon_price[0].price}</span>
            </label>
            )}
              </React.Fragment>
            ) : ''}
          </div>
          {/* <Link to='#' className={styles.more}>Show more</Link> */}
        </div>
        {/* <PrimaryButton title='ADD ITEM' info={`${currencySymbol()}${sumAddon + itemPrice}`} onClick={()=> getItemDetails()}/> */}
      </form>
    </div>
  )
}