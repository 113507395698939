// core
import React from 'react';

// components
import { Layout, ListItems } from "../../../common";

// assets
import soup from '../../../../assets/images/soup.jpg';
import { getMenu } from '../../../../redux/reducer/onboarding/selectors';
import { useSelector } from 'react-redux';
import { currentMenuTab } from '../../../../redux/reducer/main/selectors';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { loadMenuData } from '../../../../redux/reducer/main/action';
import LazyLoad from 'react-lazyload';
import LazyPlaceholder from '../../../common/LazyPlaceholder';
var jwt = require('jsonwebtoken');

export const Main = () => {
  const dispatch = useDispatch();

  const menu = useSelector(getMenu)
  // 
  useEffect(() => {
    // generateToken();
    if(menu === null || !menu) {

      dispatch(loadMenuData());
    }
    
  }, []);
  
  const currentTab = useSelector(currentMenuTab);
  const menuData = menu && menu.menu;
  const foodMenu = menuData && menuData[currentTab] &&  Object.values(menuData[currentTab]);
  const foodMenuKey = menuData && menuData[currentTab] && Object.keys(menuData[currentTab]);
  return (
    <Layout>
      <main className='home'>
        <section>
        <LazyLoad height={200}  placeholder={<LazyPlaceholder />}>
          <ListItems items={foodMenu} categoryName={foodMenuKey}  toggle />
          </LazyLoad>
          {/* <ListItems items={items2} toggle /> */}
        </section>
      </main>
    </Layout>
  );
};

