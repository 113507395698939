import React from "react";

// library
// import { useDispatch } from "react-redux";

// components
// import { AddButton } from "../AddButton/AddButton";
import { VeganStatus } from "../VeganStatus/VeganStatus";
// import { modalsActions } from "../../../redux/modals/actions";

// assets
import styles from "./GridItems.module.scss";
import { ReactComponent as ArrowSvg } from "../../../assets/images/icons/arrow.svg";
// import plate from "../../../assets/images/icons/plate.svg";
import platePng from "../../../assets/images/plate.png";
// import { getAddModalData } from "../../../redux/reducer/onboarding/actions";
import { useSelector } from "react-redux";
import { getContainsEgg, getSelectVegOnly } from "../../../redux/reducer/main/selectors";
// import { placeOrderData } from "../../../redux/reducer/main/action";
import { currencySymbol } from "../../../config/helper";

export const GridItems = ({items, toggle, categoryName}) => {
  // const dispatch = useDispatch();

  const showItems = (e) => {
    e.target.parentElement.classList.toggle(styles.hide);
  };

  // const addProduct = (data) => {
    // dispatch(getAddModalData(data));
    // dispatch(modalsActions.setShowModal('Add Product'));
  // };
  // const getPlaceOrderData = useSelector(getPlaceOrderSelector);
  // const handleAddItem = (data) => {
  //   // add directly
  //   if (data && data.item_price_details && data.item_price_details.length > 1 || data.addon_details.length) {
  //     addProduct(data);
  //   }else {
  //     const itemPrice = data && data.item_price_details && data.item_price_details.length && data.item_price_details[0] && data.item_price_details[0].item_price || 0;
  //     const itemSize =  data && data.item_price_details && data.item_price_details.length && data.item_price_details[0] && data.item_price_details[0].item_size;
  //     dispatch(placeOrderData({addons: [], item_id: data.item_id, item_size: itemSize, qty: 1}, {addons: [], item_name: data.item_name, item_id: data.item_id, item_size: itemSize, qty: 1, item_image_url: data ? data.item_image_url : plate, item_price: itemPrice, item_type: data.item_type}));
  //   }
  
  // }
  const vegOnlyState = useSelector(getSelectVegOnly);
  const containsEggState = useSelector(getContainsEgg);
  
  return (
    
      <div className={styles.items}>
        {toggle ? <h4 className={`${styles.title} ${styles.toggle}`} onClick={(e) => showItems(e)}>
            Today’s Special
            <hr />
            <ArrowSvg />
          </h4>
          : <h4 className={styles.title}>
            Today’s Special
          </h4>}
        <div className={styles.panel} >
          {items && items.length ? items.map((item, index) => (
          item && item.length && item.map((product, index) => (
            vegOnlyState === true ? containsEggState === true ? product.item_type !== 'non-veg' && 
            <div key={index} id={product.title} className={styles.item} >
              <div className={styles.image} >
                {product.item_image_url !== null ? <img src={product.item_image_url} alt={product.item_name} /> : <img src={platePng} alt={product.item_name} />}
              </div>
              <div className={styles.description}>
                <h4 className={styles.itemTitle}>
                <VeganStatus type={product.item_type} egg = {product.contains_egg} />
                  {product.item_name}
                </h4>
                <div className={styles.info}>
                  <div className={styles.price}>
                    <h4>{currencySymbol()}{product.item_price_details && product.item_price_details.length && product.item_price_details[0].item_price}</h4>
                  </div>
                  {/* <AddButton onClick={() => addProduct(product)} /> */}
                  {/* {getPlaceOrderData && getPlaceOrderData.length && getPlaceOrderData.find(o => o.item_id === product.item_id) ? <AddButton count={getPlaceOrderData.find(o => o.item_id === product.item_id).qty} id={product.item_id} /> : <AddButton id={product.item_id} />} */}
                </div>
              </div>
            </div> : product.item_type === 'veg' && product.contains_egg === false && 
            <div key={index} id={product.title} className={styles.item} >
              <div className={styles.image}>
                {product.item_image_url !== null ? <img src={product.item_image_url} alt={product.item_name} /> : <img src={platePng} alt={product.item_name} />}
              </div>
              <div className={styles.description}>
                <h4 className={styles.itemTitle}>
                <VeganStatus type={product.item_type} egg = {product.contains_egg} />
                  {product.item_name}
                </h4>
                <div className={styles.info}>
                  <div className={styles.price}>
                    <h4>{currencySymbol()}{product.item_price_details && product.item_price_details.length && product.item_price_details[0].item_price}</h4>
                  </div>
                  {/* <AddButton onClick={() => addProduct(product)} /> */}
                  {/* {getPlaceOrderData && getPlaceOrderData.length && getPlaceOrderData.find(o => o.item_id === product.item_id) ? <AddButton count={getPlaceOrderData.find(o => o.item_id === product.item_id).qty} id={product.item_id} /> : <AddButton id={product.item_id} />} */}
                </div>
              </div>
            </div> : <div key={index} id={product.title} className={styles.item} >
              <div className={styles.image}>
                {product.item_image_url !== null ? <img src={product.item_image_url} alt={product.item_name} /> : <img src={platePng} alt={product.item_name} />}
              </div>
              <div className={styles.description}>
                <h4 className={styles.itemTitle}>
                <VeganStatus type={product.item_type} egg = {product.contains_egg} />
                  {product.item_name}
                </h4>
                <div className={styles.info}>
                  <div className={styles.price}>
                    <h4>{currencySymbol()}{product.item_price_details && product.item_price_details.length && product.item_price_details[0].item_price}</h4>
                  </div>
                  {/* <AddButton onClick={() => addProduct(product)} /> */}
                  {/* {getPlaceOrderData && getPlaceOrderData.length && getPlaceOrderData.find(o => o.item_id === product.item_id) ? <AddButton count={getPlaceOrderData.find(o => o.item_id === product.item_id).qty} id={product.item_id} /> : <AddButton id={product.item_id} />} */}
                </div>
              </div>
            </div>
          ))
          )) : ''}
        </div>
      </div>
  )
}