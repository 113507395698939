// core
import React, { useEffect } from 'react';

// library
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import { useSelector } from "react-redux";

// components
import { routes } from "./routes";
import {
  Main,
  Special,
  Search,
  DriveIn,
} from "../pages";
import { getShowModal } from "../../redux/modals/selectors";

// assets
import styles from './App.module.scss';
import '../../assets/css/custom.css'
import '../../assets/css/lazyLoad.css'
import '../../assets/css/loading.scss'
import history from '../../config/history';
import Loader from '../common/Loading'

const App = () => {
  const modal = useSelector(getShowModal);

  useEffect(() => {
    if (modal !== '') {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [modal])

  return (
    <div className={styles.app}>
      <Loader />
    <Router history={history}>
    <Switch>
      <Redirect exact from='/' to={routes.special} />
      <Route path={routes.special} component={Special}/>
      <Route path={routes.drivein} component={DriveIn}/>
      <Route path={routes.search} component={Search}/>
      <Route path={routes.main} component={Main}/>
      <Route path={routes.desserts} component={Main}/>
      <Route path={routes.drinks} component={Main}/>
      <Route path={routes.store} component={Main}/>
      <Route path={routes.beverages} component={Main}/>
    </Switch>
    </Router>
  </div>
  );
}

export default App;
