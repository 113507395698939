export const types = {
	CURRENT_MENU_TAB: "CURRENT_MENU_TAB",
	PLACE_ORDER_DATA: "PLACE_ORDER_DATA",
	CHANGE_QTY: "CHANGE_QTY",
	LOAD_ORDER_DETAILS: "LOAD_ORDER_DETAILS",
	GET_ORDER_DETAILS: "GET_ORDER_DETAILS",
	PLACE_ORDER: "PLACE_ORDER",
	LOAD_COUPONS: "LOAD_COUPONS",
	GET_COUPONS: "GET_COUPONS",
	APPLY_COUPON: "APPLY_COUPON",
	LOAD_TIP: "LOAD_TIP",
	LOAD_CHECKOUT: "LOAD_CHECKOUT",
	ACKNOWLEDGE_PAYMENT: "ACKNOWLEDGE_PAYMENT",
	GET_RATING_DATA: "GET_RATING_DATA",
	SUBMIT_RATING_DATA: "SUBMIT_RATING_DATA",
	LOAD_RESTRAUNT_DETAILS: "LOAD_RESTRAUNT_DETAILS",
	GET_RESTRAUNT_DETAILS: "GET_RESTRAUNT_DETAILS",
	SUBMIT_COMPLAINT: "SUBMIT_COMPLAINT",
	LOAD_MENU_DATA: "LOAD_MENU_DATA",
	REQUEST_SERVICE: "REQUEST_SERVICE",
	VEG_ONLY: "VEG_ONLY",
	CONTAINS_EGG: "CONTAINS_EGG",
	LOAD_PLACE_PICKUP_DELIVERY: "LOAD_PLACE_PICKUP_DELIVERY",
	TOGGLE_ADD_ADDRESS: "TOGGLE_ADD_ADDRESS",
	SUBMIT_ADDRESS: "SUBMIT_ADDRESS",
	GET_ADDRESS: "GET_ADDRESS",
	LOAD_QR_DETAILS: "LOAD_QR_DETAILS",
	FETCH_BRAND_MENU: "FETCH_BRAND_MENU"
};
