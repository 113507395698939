export const types = {
	SEND_OTP: "SEND_OTP",
	SEND_OTP_SUCESS: "SEND_OTP_SUCESS",
	VERIFY_OTP: "VERIFY_OTP",
	GET_TABLE_JOIN_DETAILS: "GET_TABLE_JOIN_DETAILS",
	LOAD_TABLE_JOIN_DETAILS: "LOAD_TABLE_JOIN_DETAILS",
	SUBMIT_TABLE_JOIN_CODE: "SUBMIT_TABLE_JOIN_CODE",
	GET_TABLE_CODE: "GET_TABLE_CODE",
	LOAD_GET_TABLE_CODE: "LOAD_GET_TABLE_CODE",
	GET_ADD_MODAL_DATA: "GET_ADD_MODAL_DATA",
	CHANGE_NAME: "CHANGE_NAME",
	LOADER: "LOADER",
	GET_DRIVE_IN_BRANDS: "GET_DRIVE_IN_BRANDS",
	GET_RESTAURANT_DETAILS : "GET_RESTAURANT_DETAILS"
};
