import React, { useRef, useState } from "react";

// library
import { Link } from "react-router-dom";

// components
import { ListItems, NoNavigationLayout } from "../../../common";
import { routes } from "../../../App/routes";

// assets
import styles from './Search.module.scss';
import saly6 from "../../../../assets/images/saly-6.png";
import banner from "../../../../assets/images/search-banner.png";
import { ReactComponent as SearchSvg } from "../../../../assets/images/icons/search.svg";
import { ReactComponent as BackSvg } from "../../../../assets/images/icons/arrow-back.svg";
import { useSelector } from "react-redux";
import placeOrderStyles from '../PlaceOrder/PlaceOrder.module.scss';
import {getMenu} from '../../../../redux/reducer/onboarding/selectors';

export const Search = () => {
  const [items, setItems] = useState(null);

  let refSearch = useRef(null);

  const menuData = useSelector(getMenu);
  const allMenues = menuData && menuData.menu;
  const flatSuperCategory = allMenues && Object.values(allMenues);
  const arrPush = [];
  flatSuperCategory && flatSuperCategory.flatMap(el => Object.values(el).map(el2 => el2.map(el3 => arrPush.push(el3))))

  const handleClickSearch = (e) => {
    if (e.target.value.length === 0) {
      setItems(null)
    } else {
      const post = arrPush.filter(item =>
        item.item_name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setItems([post]);
    }
  }
  return (
    <NoNavigationLayout>
      <main className={styles.search}>
        <div className={styles.panel}>
          <Link to={routes.special}>
            <BackSvg />
          </Link>
          <label>
            <input ref={refSearch} type='text' onChange={(e) => handleClickSearch(e)}  placeholder='Search your food' />
            <div className={styles.searchIcon} onClick={() => handleClickSearch()}>
              <SearchSvg />
            </div>
          </label>
        </div>
        {items && items[0] && items[0].length ? <section>
            <ListItems items={items} mod/>
          </section>
          : 
          items === null ? 
          <section> 
            {/* <div className={styles.card}>
          <div>
            <p>Item not found</p>
            <h5>Explore Events {">"}</h5>
          </div>
          <img src={saly5} alt='saly5' />
        </div> */}
        <img className={styles.banner} src={banner} alt='' />
      </section> 
      : 
      <React.Fragment> 
            <section className={placeOrderStyles.content}>
          <h3>Nothing here!</h3>
          <p>Add something from the menu.</p>

          <img className={placeOrderStyles.banner} src={saly6} alt='' />
        </section>
        
        
        </React.Fragment>}
      </main>
    </NoNavigationLayout>

  )
}