/* eslint-disable no-useless-concat */
import { takeEvery, put, take } from "redux-saga/effects";
import { types } from "../reducer/main/types";
import { callApi, displayNotification } from "../../config/helper";
import { getTableJoinDetails, loader, getDriveInBrands, getRestaurantDetails } from "../reducer/onboarding/actions";
import { routes } from "../../components/App/routes";
import history from "../../config/history";

function* loadMenuDataSaga(action) {
	yield put(loader(true));
	try {
		// const orderType = JSON.parse(localStorage.getItem("type"));
		console.log(`menuDatasga`);
		const qrCode = localStorage.getItem("qrCode");
		const payload = { qr_code: qrCode };
		let apiUrl = "/view_catalogue/menu";

		const response = yield callApi(apiUrl, payload, "POST");
		if (response && response.data) {
			yield put(loader(false));
			if(response.data.drive_in && response.data.brand_map && response.data.brand_map.length > 0) {
				yield put(getRestaurantDetails(response.data.menu && response.data.menu.restraunt_details))
				yield put(getDriveInBrands(response.data.brand_map))
				history.push((`${routes.drivein}?qr_code=${qrCode}`));
			}
			else{
				yield put(getTableJoinDetails(response.data));
				if (response.data.menu && response.data.menu.restraunt_details) {
					localStorage.setItem(
						"restrauntId",
						JSON.stringify(response.data.menu.restraunt_details.restraunt_id)
					);
				}
			}
		} else if (response.message === "Incorrect padding") {
			yield put(loader(false));
			displayNotification("response.message");
		}
	} catch (error) {
		yield put(loader(false));
		console.log("error", error);
	}
}

function* fetchBrandMenuSaga(action) {
	yield put(loader(true));
	try {
		const qrCode = localStorage.getItem("qrCode");
		const payload = { qr_code: qrCode };
		let apiUrl = `/view_catalogue/menu/dine_in/brand/${action.payload}`;

		const response = yield callApi(apiUrl, payload, "POST");
		if (response && response.data) {
			yield put(loader(false));
			yield put(getTableJoinDetails(response.data));
			if (response.data.menu && response.data.menu.restraunt_details) {
				localStorage.setItem(
					"restrauntId",
					JSON.stringify(response.data.menu.restraunt_details.restraunt_id)
				);
			}
			history.push(`/view_catalogue?qr_code=${qrCode}`)
		} else if (response.message === "Incorrect padding") {
			yield put(loader(false));
			displayNotification("response.message");
		}
	} catch (error) {
		yield put(loader(false));
		console.log("error", error);
	}
}

export function* mainSaga() {
	yield takeEvery(types.LOAD_MENU_DATA, loadMenuDataSaga);
	yield takeEvery(types.FETCH_BRAND_MENU , fetchBrandMenuSaga);
}
